import "../App.css";
import {
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Typography,
  Divider,
  Spin,
  Card,
} from "antd";
import { useState } from "react";
import {
  summaryBuilder,
  toFormat,
  toHumanReadable,
} from "../lib/util";
import { getValidatorData } from "../service/validator.service";
import { fetchChzPrice } from "../service/price.service";
import BigNumber from "bignumber.js";

const { Title } = Typography;

const ValidatorStatus = {
  0: "NotFound",
  1: "Active",
  2: "Pending",
  3: "Jail",
};

function App() {
  const [epochs, setEpochs] = useState([]);
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validator, setValidator] = useState(
    "0xBf968b8a455dA0DFc0852899c334b6f5F6e20B5f"
  );
  const [nodeUrl, setNodeUrl] = useState("https://chiliz-rpc.publicnode.com");

  const handleValidatorInputChange = (e) => {
    setValidator(e.target.value);
  };

  const handleNodeUrlInputChange = (e) => {
    setNodeUrl(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const { validatorFeeReward, validatorStatus, epochs,currentEpoch,remainingBlocks } =
        await getValidatorData(validator, nodeUrl);
      const chzPrice = await fetchChzPrice().catch((e) => 0);

      const summary = summaryBuilder({
        currentEpoch: currentEpoch,
        remainingBlocks: remainingBlocks,
        ownerAddress: validatorStatus.ownerAddress,
        commissionRate: "%" + Number(validatorStatus.commissionRate) / 100,
        jailedBefore: Number(validatorStatus.jailedBefore),
        slashesCount: Number(validatorStatus.slashesCount),
        status: ValidatorStatus[Number(validatorStatus.status)],
        totalDelegated: toFormat(
          toHumanReadable(validatorStatus.totalDelegated)
        ),
        totalRewards: toFormat(toHumanReadable(validatorStatus.totalRewards)),
        validatorFeeReward: toFormat(toHumanReadable(validatorFeeReward)),
        chzPrice: chzPrice + " USD",
        validatorFeeRewardUSD:
          toFormat(
            BigNumber(toHumanReadable(validatorFeeReward)).multipliedBy(
              chzPrice
            )
          ) + " USD",
      });

      setSummary(summary);
      setEpochs(epochs);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App" style={{ padding: "20px" }}>
      <Row justify="center" style={{ marginBottom: "50px" }}>
        <Col span={24}>
          <Title
            level={2}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Validator Dashboard
          </Title>
        </Col>
        <Col span={8}>
          <Input
            value={validator}
            style={{ marginBottom: "10px" }}
            placeholder="Validator Address"
            onChange={handleValidatorInputChange}
          />
          <Input
            value={nodeUrl}
            style={{ marginBottom: "10px" }}
            placeholder="Node Url"
            onChange={handleNodeUrlInputChange}
          />
          <Button onClick={fetchData} disabled={loading} type="primary" block>
            {loading ? <Spin /> : "Fetch Validator Data"}
          </Button>
        </Col>
      </Row>
      <Divider>Validator Summary</Divider>
      <Row justify="center" style={{ marginBottom: "50px" }}>
        <Col span={16}>
          <Descriptions bordered items={summary} />
        </Col>
      </Row>

      <Divider>Epochs (Last 100)</Divider>
      <Row gutter={[16, 16]} justify="center" style={{ marginBottom: "50px" }}>
        {epochs.map((item) => (
          <Col span={4} key={item.validatorAddress}>
            <Card
              title={`Epoch ${item.epoch}`}
              hoverable
              style={{ textAlign: "center" }}
            >
              <p>
                <b>Total Delegated:</b> {toFormat(toHumanReadable(item.totalDelegated))}
              </p>
              <p>
                <b>Total Reward:</b> {toFormat(toHumanReadable(item.totalRewards))}
              </p>
              <p>
                <b>Slashes Count:</b> {Number(item.slashesCount)}
              </p>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default App;

import Web3 from "web3";
import { toHex } from "web3-utils";
import stakingAbi from "../lib/abis/staking.abi.json";
import { toHumanReadable } from "../lib//util";
import BigNumber from "bignumber.js";

let web3, stakingContract;

const stakingAddress = "0x0000000000000000000000000000000000001000";
const blockOfEpoch = 28800;

export const getDelegatarData = async (address, nodeUrl) => {
  web3 = new Web3(nodeUrl || "https://rpc.chiliz.com");
  stakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);
  const validators = await stakingContract.methods.getValidators().call();
  const lastBlockNumber = Number(await getLastBlockNumber());
  const remainingBlocks = blockOfEpoch - (lastBlockNumber % blockOfEpoch);
  const transactions = await getDelegatorTransactions(address);

  const delegationInfo = {
    totalDelegated: BigNumber(0),
    totalPendingReward: BigNumber(0),
    totalReward: BigNumber(0),
    lastBlockNumber: lastBlockNumber,
    remainingBlocks: `${remainingBlocks} / ${blockOfEpoch}`,
    currentEpoch: (await getCurrentEpoch()).toString(),
    validators: [],
    transactions,
  };

  for (const validator of validators) {
    const validatorDelegationInfo = await getDelegationInfo({
      validator,
      delegator: address,
    });

    if (
      Number(validatorDelegationInfo.delegatedAmount) !== 0 ||
      Number(validatorDelegationInfo.rewards) !== 0 ||
      Number(validatorDelegationInfo.unClaimedRewards) !== 0 ||
      Number(validatorDelegationInfo.pendingReward) !== 0
    ) {
      delegationInfo.validators.push({
        validatorAddress: validator,
        ...validatorDelegationInfo,
      });

      delegationInfo.totalDelegated = BigNumber(
        delegationInfo.totalDelegated.plus(
          validatorDelegationInfo.delegatedAmount
        )
      );
      delegationInfo.totalReward = BigNumber(
        delegationInfo.totalReward.plus(validatorDelegationInfo.rewards)
      );

      delegationInfo.totalPendingReward = BigNumber(
        delegationInfo.totalPendingReward.plus(
          validatorDelegationInfo.pendingReward
        )
      );
    }
  }

  delegationInfo.totalReward = delegationInfo.totalReward.toFixed(0);
  delegationInfo.totalDelegated = delegationInfo.totalDelegated.toFixed(0);
  delegationInfo.totalPendingReward =
    delegationInfo.totalPendingReward.toFixed(0);

  console.log("-----------------------------------------");
  console.log(delegationInfo);
  console.log("-----------------------------------------");

  return delegationInfo;
};

const getDelegatorTransactions = async (delegator) => {
  const formattedDelegator = web3.utils.padLeft(delegator, 64).toLowerCase();
  const latestBlockNumber = Number(await getLastBlockNumber());
  const earliestBlockNumber = latestBlockNumber - 1728000;
  const gap = 50000;
  let transactions = [];

  for (let i = latestBlockNumber; i > earliestBlockNumber; i -= gap) {
    console.log("fetching undelegations", i, i - gap);

    const partOfUndelegations = await stakingContract.getPastEvents({
      fromBlock: toHex(i - gap),
      toBlock: toHex(i),
      address: stakingAddress,
      topics: [null, null, formattedDelegator],
    });

    transactions.push(...partOfUndelegations);
  }

  return transactions.map((u) => ({
    amount: toHumanReadable(u.returnValues.amount.toString()),
    epoch: u.returnValues.epoch.toString(),
    validator: u.returnValues.validator,
    transactionHash: u.transactionHash,
    type: getTransactionType(u),
  }));
};

const getTransactionType = (log) => {
  let type = "unknown";

  switch (log.topics[0]) {
    case "0x30bcda2f188b532c7644e632473e83a6fb3c5c79717650d0ac790d141bb1b177":
      type = "delegate";
      break;
    case "0xa410e32157a44414a502bb47d775234de1aa7da123f5adfe426898f1601883fd":
      type = "undelegate";
      break;
    case "0xb22dec804803f8b1c5333f626cdbfdfb1bd629f1e1bb45dcfb22b5f74ed46b1c":
      type = "claim";
      break;
    case "0xa82f74002b6639f6cfc2cfd4f3ade1998108eda0f484d9064e3098c211e81d6e":
      type = "redelegate";
      break;

    default:
      break;
  }

  return type;
};

const getLastBlockNumber = async () => web3.eth.getBlockNumber();

const getDelegatedAmount = async ({ validator, delegator }) =>
  stakingContract.methods.getValidatorDelegation(validator, delegator).call();

const getPendingReward = async ({ validator, delegator }) =>
  stakingContract.methods.getPendingDelegatorFee(validator, delegator).call();

const getUnClaimedReward = async ({ validator, delegator }) =>
  stakingContract.methods
    .calcAvailableForRedelegateAmount(validator, delegator)
    .call();

const getDelegatorReward = async ({ validator, delegator }) =>
  stakingContract.methods.getDelegatorFee(validator, delegator).call();

const getCurrentEpoch = () => stakingContract.methods.currentEpoch().call();

const getDelegationInfo = async ({ validator, delegator }) => {
  const { delegatedAmount } = await getDelegatedAmount({
    validator,
    delegator,
  });

  const delegateRewards = await getDelegatorReward({ validator, delegator });
  const pendingReward = await getPendingReward({ validator, delegator });
  const unClaimedRewards = (await getUnClaimedReward({ validator, delegator }))
    .amountToStake;

  return {
    delegatedAmount: toHumanReadable(delegatedAmount.toString()),
    rewards: toHumanReadable(delegateRewards.toString()),
    unClaimedRewards: toHumanReadable(unClaimedRewards.toString()),
    pendingReward: toHumanReadable(pendingReward.toString()),
  };
};

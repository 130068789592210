import Web3 from "web3";
import stakingAbi from "../lib/abis/staking.abi.json";

let web3, stakingContract;

const stakingAddress = "0x0000000000000000000000000000000000001000";
const blockOfEpoch = 28800;

export const getValidatorData = async (address, nodeUrl) => {
  web3 = new Web3(nodeUrl || "https://rpc.chiliz.com");
  stakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);

  const currentEpoch = Number(
    await stakingContract.methods.currentEpoch().call()
  );
  const validatorStatus = await stakingContract.methods
    .getValidatorStatus(address)
    .call();
  const validatorFeeReward = await stakingContract.methods
    .getValidatorFee(address)
    .call();

  const epochs = await getValidatorEpochsStatus(address, currentEpoch);
  const lastBlockNumber = Number(await getLastBlockNumber());
  const remainingBlocks = blockOfEpoch - (lastBlockNumber % blockOfEpoch);

  const validatorInfo = {
    currentEpoch,
    remainingBlocks,
    validatorStatus,
    validatorFeeReward,
    epochs,
  };

  console.log("-----------------------------------------");
  console.log(validatorInfo);
  console.log("-----------------------------------------");

  return validatorInfo;
};

const getValidatorEpochsStatus = async (validator, currentEpoch) => {
  let epochs = [];

  for (let i = currentEpoch; i > currentEpoch - 100; i -= 1) {
    const validatorStatus = await stakingContract.methods
      .getValidatorStatusAtEpoch(validator, i)
      .call();

    epochs.push({
      ...validatorStatus,
      epoch: i,
    });
  }

  return epochs;
};


const getLastBlockNumber = async () => web3.eth.getBlockNumber();

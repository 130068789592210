import "../App.css";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  Row,
  Table,
  Typography,
  Divider,
  Spin,
} from "antd";
import { useState } from "react";
import { getDelegatarData } from "../service/delegator.service";
import { shortenAddress, summaryBuilder, toFormat } from "../lib/util";
import BigNumber from "bignumber.js";
import { fetchChzPrice } from "../service/price.service";

const { Title } = Typography;

const transactionColumns = [
  {
    title: "Transaction Hash",
    dataIndex: "transactionHash",
    key: "transactionHash",
    render: (text) => (
      <a
        target="_blank"
        href={`https://chiliscan.com/tx/${text}`}
        rel="noreferrer"
      >
        {shortenAddress(text)}
      </a>
    ),
  },
  {
    title: "Validator",
    dataIndex: "validator",
    key: "validator",
  },
  {
    title: "Epoch",
    dataIndex: "epoch",
    key: "epoch",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text) => toFormat(text),
  },
];

function App() {
  const [validators, setValidators] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delegator, setDelegator] = useState(
    "0xfb4816b2f573C9358c13F9102C071cDb5FCa428d"
  );
  const [nodeUrl, setNodeUrl] = useState("https://chiliz-rpc.publicnode.com");

  const handleDelegatorInputChange = (e) => {
    setDelegator(e.target.value);
  };

  const handleNodeUrlInputChange = (e) => {
    setNodeUrl(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getDelegatarData(delegator, nodeUrl);
      const chzPrice = await fetchChzPrice().catch((e) => 0);

      const summary = summaryBuilder({
        currentEpoch: data.currentEpoch,
        lastBlockNumber: data.lastBlockNumber,
        remainingBlocks: data.remainingBlocks,
        totalDelegated: toFormat(data.totalDelegated),
        totalReward: toFormat(data.totalReward),
        chzPrice: chzPrice + " USD",
        totalRewardUsd:
          toFormat(BigNumber(data.totalReward).multipliedBy(chzPrice)) + " USD",
        totalPendingReward: toFormat(data.totalPendingReward),
      });

      setValidators(data.validators);
      setTransactions(data.transactions);
      setSummary(summary);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App" style={{ padding: "20px" }}>
      <Row justify="center" style={{ marginBottom: "50px" }}>
        <Col span={24}>
          <Title
            level={2}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Delegator Dashboard
          </Title>
        </Col>
        <Col span={8}>
          <Input
            value={delegator}
            style={{ marginBottom: "10px" }}
            placeholder="Delegator Address"
            onChange={handleDelegatorInputChange}
          />
          <Input
            value={nodeUrl}
            style={{ marginBottom: "10px" }}
            placeholder="Node Url"
            onChange={handleNodeUrlInputChange}
          />
          <Button onClick={fetchData} disabled={loading} type="primary" block>
            {loading ? <Spin /> : "Fetch Delegator Data"}
          </Button>
        </Col>
      </Row>
      <Divider>Delegator Summary</Divider>
      <Row justify="center" style={{ marginBottom: "50px" }}>
        <Col span={16}>
          <Descriptions bordered items={summary} />
        </Col>
      </Row>
      <Divider>Validators</Divider>
      <Row gutter={[16, 16]} justify="center" style={{ marginBottom: "50px" }}>
        {validators.map((item) => (
          <Col span={6} key={item.validatorAddress}>
            <Card
              title={`Validator ${shortenAddress(item.validatorAddress)}`}
              hoverable
              style={{ textAlign: "center" }}
            >
              <a
                target="_blank"
                href={`https://scan.chiliz.com/address/${item.validatorAddress}`}
                rel="noreferrer"
              >
                See on Explorer
              </a>
              <p>
                <b>Delegated Amount:</b> {toFormat(item.delegatedAmount)}
              </p>
              <p>
                <b>Delegator Reward:</b> {toFormat(item.rewards)}
              </p>
              <p>
                <b>Unclaimed Reward:</b> {toFormat(item.unClaimedRewards)}
              </p>
              <p>
                <b>Pending Reward:</b> {toFormat(item.pendingReward)}
              </p>
            </Card>
          </Col>
        ))}
      </Row>
      <Divider>Stake Transactions (Last 3 months)</Divider>
      <Row justify="center" style={{ marginBottom: "50px" }}>
        <Col span={24}>
          <Table
            dataSource={transactions}
            columns={transactionColumns}
            bordered
            pagination={{ pageSize: 10 }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default App;

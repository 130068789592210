import React from "react";
import { Layout, Menu } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import DelegatorDashboard from "./pages/DelegatorDashboard";
import ValidatorDashboard from "./pages/ValidatorDashboard";

const { Content, Footer, Sider } = Layout;

function App() {
  const navigate = useNavigate();

  const menuItems = [
    { key: "1", label: "Delegator Dashboard", onClick: () => navigate("/delegator") },
    { key: "2", label: "Validator Dashboard", onClick: () => navigate("/validator") },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider theme="dark">
        <div style={{ color: "white", textAlign: "center", margin: "20px 0" }}>
          <h2>CHZ Staking Dashboard</h2>
        </div>
        <Menu theme="dark" mode="inline" items={menuItems} />
      </Sider>
      <Layout>
        <Content style={{ background: "#fff" }}>
          <Routes>
            <Route path="/delegator" element={<DelegatorDashboard />} />
            <Route path="/validator" element={<ValidatorDashboard />} />
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>© 2024 by Paribu</Footer>
      </Layout>
    </Layout>
  );
}

export default App;

import BigNumber from "bignumber.js";

export function toHumanReadable(value, decimal = 18) {
  return new BigNumber(value.toString())
    .times(new BigNumber(10).pow(-decimal))
    .decimalPlaces(decimal)
    .toFixed(0);
}

export function toFormat(value, decimal = 0) {
  return new BigNumber(value.toString()).toFormat(decimal);
}

export function summaryBuilder(obj, span = 2) {
  const arr = [];

  for (const element in obj) {
    arr.push({
      label: camelToTitleCase(element),
      children: obj[element],
      span: span,
    });
  }

  return arr;
}

function camelToTitleCase(text) {
  const words = text.replace(/([a-z])([A-Z])/g, "$1 $2");

  return words
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function shortenAddress(address) {
  if (!address || address.length < 10) {
    throw new Error("Invalid address");
  }
  const start = address.slice(0, 7);
  const end = address.slice(-7);
  return `${start}..${end}`;
}
